import v_0 from "./pages/404.md";
import v_1 from "./pages/index.md";
import v_2 from "./pages/blog/index.md";
import v_3 from "./pages/blog/introduction.md";
import errorPage from "./components/Error404.vue";
export let routes = [
	{ path: "/404.html", component: v_0 },
	{ path: "/", component: v_1 },
	{ path: "/blog/", component: v_2 },
	{ path: "/blog/introduction.html", component: v_3 },
	{path: "*", component: errorPage}
];
export let urls = {
    "/404.html": "/404.html",
    "/index.html": "/",
    "/blog/index.html": "/blog/",
    "/blog/introduction.html": "/blog/introduction.html"
}
const components = {};
import Homepage from "./components/Homepage.vue";
components[Homepage.name] = Homepage;
import Blog from "./components/Blog.vue";
components[Blog.name] = Blog;
import Page from "./components/Page.vue";
components[Page.name] = Page;
import Error404 from "./components/Error404.vue";
components[Error404.name] = Error404;
import PreCustom from "./components/PreCustom.vue";
components[PreCustom.name] = PreCustom;
import ACustom from "./components/ACustom.vue";
components[ACustom.name] = ACustom;
export { components } ;