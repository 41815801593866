<template>
<Layout :frontmatter="frontmatter" :extra="extra"></Layout>
</template>
<script>
import Layout from "@/components/Blog.vue";
export default {
	components:{"Layout": Layout},
	data () {
		return {
			frontmatter: {"title":"vlow - blog","index":"."},
			extra: [{"title":"vlow - blog","index":".","path":"/blog/index.html","excerpt":""},{"title":"introduction","shadow":"前書き","date":"2020-04-10T00:00:00.000Z","path":"/blog/introduction.html","excerpt":""}]
		}
	}
}
</script>