import Vue from 'vue'
import VueRouter from 'vue-router'
import { urls, routes, components } from "./site.config";

import './static/global.scss';

Vue.use(VueRouter)
Vue.config.productionTip = false

for (const name in components) {
    Vue.component(name, components[name])
}

function createApp () {
    const router = new VueRouter({
        mode: 'history',
        routes
    })

    Vue.mixin({
        mounted: function () {
            if (this.frontmatter) {
                document.title = this.frontmatter.title
            }
        }
    })

    const app = new Vue({
        router,
        render: h => h('router-view'),
    })

    return app
}

if( typeof window !== 'undefined' ) {
    window.App = createApp
}

export { urls, createApp as default }