var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "site-wrapper" }, [
    _c("header", [
      _c("nav", [
        _c(
          "div",
          [
            _c("router-link", { staticClass: "logo", attrs: { to: "/" } }, [
              _vm._v("vlow")
            ]),
            _vm._v("/"),
            _c(
              "a",
              {
                staticClass: "router-link-exact-active",
                attrs: { href: _vm.$route.path }
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "glitch animated",
                    attrs: { title: _vm.path }
                  },
                  [_vm._v(_vm._s(_vm.path))]
                ),
                _vm._v(" "),
                _vm.hasExt
                  ? _c("span", { staticClass: "dim" }, [_vm._v(".html")])
                  : _vm._e()
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("ul", [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/blog/" } }, [_vm._v("blog")])],
            1
          ),
          _vm._v(" "),
          _vm._m(0)
        ])
      ])
    ]),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _vm._m(2)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("a", { staticStyle: { cursor: "not-allowed" } }, [
        _vm._v("download"),
        _c("sup", [_vm._v("TBA")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("aside", [
      _c("h1", [
        _c(
          "span",
          {
            staticClass: "glitch animated alt",
            attrs: { title: "404 Not Found" }
          },
          [_vm._v("404 Not Found")]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("aside", { staticClass: "justify" }, [
      _c("p", [
        _vm._v(
          "The server has not found anything matching the Request-URI. No indication could be given of whether the condition is temporary or permanent. The 410 (Gone) status code WOULD have been used if the server knew, through some internally configurable mechanism, that an old resource is permanently unavailable and has no forwarding address. This status code was used because the server does not wish to reveal exactly why the request has been refused, or because no other response is applicable."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }