<template>
  <div class="site-wrapper">
    <header>
      <nav>
        <div>
          <a-custom href="/" class="logo">vlow</a-custom>
        </div>
        <ul>
          <li><a-custom href="/blog">blog</a-custom></li>
          <li><a style="cursor: not-allowed;">download<sup>TBA</sup></a></li>
        </ul>
      </nav>
      <slot name="demo"></slot>
    </header>

    <aside class="justify fullwidth">
      <slot name="body"></slot>
    </aside>

    <main v-for="item in posts" v-bind:key="item.path">
      <h2><a-custom :href="item.path" >{{item.title}}</a-custom><time>{{item.date.substr(0,10)}}</time></h2>
      <article v-html="item.excerpt"></article>
    </main>
  </div>
</template>

<script>
import ACustom from "@/components/ACustom.vue";

export default {
  name: "Homepage",
  components: {ACustom},
  props: {
    frontmatter: Object,
    extra: Array
  },
  computed: {
    posts () {
      return [...this.extra].filter(a=>!!a.date).sort((a,b)=>{
        return a.date > b.date ? -1 : 1
      }).slice(0,1)
    }
  }
}
</script>

<style>
.logo.router-link-exact-active:after {
  content: "ながれ";
}
</style>