<template>
  <div class="site-wrapper">
    <header>
      <nav>
        <div>
          <router-link to="/" class="logo">vlow</router-link>/<a :href="$route.path" class="router-link-exact-active">
            <span class="glitch animated" :title="path">{{ path }}</span>
            <span v-if="hasExt" class="dim">.html</span>
          </a>
        </div>
        <ul>
          <li><router-link to="/blog/">blog</router-link></li>
          <li><a style="cursor: not-allowed;">download<sup>TBA</sup></a></li>
        </ul>
      </nav>
    </header>

    <aside>
      <h1><span class="glitch animated alt" title="404 Not Found">404 Not Found</span></h1>
    </aside>
    <aside class="justify">
      <p>The server has not found anything matching the Request-URI. No indication could be given of whether the condition is temporary or permanent. The 410 (Gone) status code WOULD have been used if the server knew, through some internally configurable mechanism, that an old resource is permanently unavailable and has no forwarding address. This status code was used because the server does not wish to reveal exactly why the request has been refused, or because no other response is applicable.</p>
    </aside>
  </div>
</template>

<script>
export default {
  name: "Error404",
  data () {
    return {
      path: "404",
      hasExt: false
    }
  },
  mounted () {
    this.path = this.$route.path.replace(/\.html$/,'').replace(/^\//,'')
    this.hasExt = this.$route.path.endsWith('.html')
    if(document) {
      document.title = "Not found"
    }
  }
}
</script>

<style lang="scss">
.glitch {
  color: white;
  position: relative;

  //clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  //background: red;

  &:before, &:after {
    color: white;
    content: attr(title);
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: 2px;
    line-height: 100%;
    background: #121314;
    z-index: 1;
  }
  &:before {
    left: 1px;
    text-shadow: -1px 0 #ff0102;
  }
  &:after {
    left: -1px;
    text-shadow: 1px 0 #00feff;
  }

  &.animated {
    &:before, &:after {
      animation-duration: 6s;
      animation-timing-function: linear;
      animation-delay: 0s;
      animation-iteration-count: infinite;
      animation-direction: alternate-reverse;
    }
    &:before {
      animation-name: glitch-animation-1;
    }
    &:after {
      animation-name: glitch-animation-2;
    }
    &.alt:before {
      animation-name: glitch-animation-3;
    }
    &.alt:after {
      animation-name: glitch-animation-4;
    }
  }
  //:not(.animated) {
    &:before {
      clip-path: polygon(0 65%, 100% 65%, 100% 100%, 0 100%);
    }
    &:after {
      clip-path: polygon(0 30%, 100% 30%, 100% 50%, 0 50%);
    }
  //}
}

@for $j from 1 through 4 {
  @keyframes glitch-animation-#{$j} {
    $steps: 20;
    @for $i from 0 through $steps {
      #{percentage($i*(1/$steps))} {
        $top: percentage(random(100)/100);
        $bottom: percentage(random(100)/100);
        clip-path: polygon(0 $top, 100% $top, 100% $bottom, 0 $bottom);
      }
    }
  }
}
</style>