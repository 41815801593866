var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.lang === "vlow"
    ? _c("vlow-pre", [_vm._v(_vm._s(_vm.content))])
    : _c("pre", { class: _vm.lang }, [_c("code", [_vm._v(_vm._s(_vm.cnt))])])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }