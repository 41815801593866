var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    { attrs: { frontmatter: _vm.frontmatter } },
    [
      _c("p", [
        _vm._v(
          "Back in the day most people could not write. If you needed something written, you would hire a scribe, and they would write it down. Scribes were people trained in special institutions in writing."
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Eventually more people were able to write, and writing started being used as the extension of one's mind. It is hard to multiply eight-digit number in mind, but it's easy to do on paper. Writing allows us to think thoughts we could not think before."
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Nowadays most people can not program. If you need something programmed, you hire a developer, and they would program it. Developers are people trained in special institutions in programming."
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Eventually more people will be able to program, and programing will be used an extension of one's mind. There is plethora of tasks hard to do in mind or on paper, but it's easy to let a computer do it. Programing allows us to think thoughts we could not think before."
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _c("strong", [
          _c("code", [_vm._v("vlow")]),
          _vm._v("'s goal is to aid in that")
        ])
      ]),
      _vm._v(" "),
      _c(
        "h2",
        {
          attrs: {
            id: "programing-in-vlow-should-be-as-easy-as-sketching-on-paper"
          }
        },
        [
          _vm._v("Programing in "),
          _c("code", [_vm._v("vlow")]),
          _vm._v(" should be as easy as sketching on paper "),
          _c(
            "ACustom",
            {
              staticClass: "header-anchor",
              attrs: {
                href:
                  "#programing-in-vlow-should-be-as-easy-as-sketching-on-paper"
              }
            },
            [_vm._v("¶")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "We aim to develop a universally applicable language that is easy to start working with and easy to reason about."
        )
      ]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [
          _c("p", [
            _c("code", [_vm._v("vlow")]),
            _vm._v(" is to be a universally applicable")
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v("by compiling "),
            _c("code", [_vm._v("vlow")]),
            _vm._v(" to native executables there are no restriction where "),
            _c("code", [_vm._v("vlow")]),
            _vm._v(
              " code can be used: be it a webservice, or a simple gui tool"
            )
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("p", [_vm._v("graphical language")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "as those have a reputation of being easy to get started for non-technical people, by eliminating the burden of syntax and typos"
            )
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("p", [_vm._v("using the dataflow oriented paradigm")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "as this is a pattern that is natural to think and reason about"
            )
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("p", [
            _vm._v(
              "having a strong static type-system, with powerful generic and templating system"
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "as this will prevent a lot of mistakes and allows for great autocompletion and development time code checks"
            )
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("p", [_vm._v("and strong tooling support")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "an IDE, a debugger, version control support, testing frameworks are essential for a language to ever hope to hold its own"
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "h2",
        { attrs: { id: "development" } },
        [
          _vm._v("Development "),
          _c(
            "ACustom",
            { staticClass: "header-anchor", attrs: { href: "#development" } },
            [_vm._v("¶")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("p", [
        _vm._v("Currently "),
        _c("code", [_vm._v("vlow")]),
        _vm._v(
          " is in no shape to be presented to the world, but we strive to post bi-weekly updates on this blog."
        )
      ]),
      _vm._v(" "),
      _c("PreCustom", {
        attrs: {
          lang: "vlow",
          content:
            '[\n  {"id":"BD346D10-4A7B-422F-889B-13100D043AB2","type":"entry","x":-311,"y":12,"node":{}},\n  {"id":"0CBDD21B-03EA-4488-98F7-11FD94C374A5","type":"mapToValue","x":-135,"y":12,"node":{\n    "$0":{"type":"connection","connection":{"source":"BD346D10-4A7B-422F-889B-13100D043AB2","pin":"out"}},\n    "$1":{"type":"constant","constant":{"value":"\\"stay tuned\\""}}}},\n  {"id":"424390E7-EE8D-495F-9116-9BA8999C910B","type":"print","x":161,"y":12,"node":{\n    "string":{"type":"connection","connection":{"source":"0CBDD21B-03EA-4488-98F7-11FD94C374A5","pin":"out"}}}}\n]\n'
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }