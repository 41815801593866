<template>
<Layout :frontmatter="frontmatter" :extra="extra"><template v-slot:demo>
<PreCustom lang="vlow" content="[
  {
    &quot;id&quot;: &quot;1B4ACB17-B35A-4C60-B7CE-42AA9A70B0DC&quot;,
    &quot;type&quot;: &quot;stride&quot;,
    &quot;x&quot;: -612,
    &quot;y&quot;: -1,
    &quot;node&quot;: {
      &quot;from&quot;: {&quot;type&quot;: &quot;constant&quot;, &quot;constant&quot;: {&quot;value&quot;: 0}},
      &quot;to&quot;: {&quot;type&quot;: &quot;constant&quot;, &quot;constant&quot;: {&quot;value&quot;: 100}},
      &quot;by&quot;: {&quot;type&quot;: &quot;constant&quot;, &quot;constant&quot;: {&quot;value&quot;: 1}}
    }
  }, {
  &quot;id&quot;: &quot;0CBDD21B-03EA-4488-98F7-11FD94C374A5&quot;,
  &quot;type&quot;: &quot;mapValues&quot;,
  &quot;x&quot;: -367,
  &quot;y&quot;: 0,
  &quot;width&quot;: 538,
  &quot;height&quot;: 254,
  &quot;node&quot;: {
    &quot;array&quot;: {
      &quot;type&quot;: &quot;connection&quot;,
      &quot;connection&quot;: {&quot;source&quot;: &quot;1B4ACB17-B35A-4C60-B7CE-42AA9A70B0DC&quot;, &quot;pin&quot;: &quot;out&quot;}
    }, &quot;result&quot;: {&quot;type&quot;: &quot;connection&quot;, &quot;connection&quot;: {&quot;source&quot;: &quot;BD84E1DF-2D8E-4444-B16E-4223EDC34F4B&quot;, &quot;pin&quot;: &quot;ret&quot;}}
  }
}, {
  &quot;id&quot;: &quot;B0BE0799-454E-4498-97D1-BD88809BEC12&quot;,
  &quot;type&quot;: &quot;modulo&quot;,
  &quot;x&quot;: -202,
  &quot;y&quot;: 25,
  &quot;node&quot;: {
    &quot;a&quot;: {
      &quot;type&quot;: &quot;connection&quot;,
      &quot;connection&quot;: {&quot;source&quot;: &quot;0CBDD21B-03EA-4488-98F7-11FD94C374A5&quot;, &quot;pin&quot;: &quot;element&quot;}
    }, &quot;b&quot;: {&quot;type&quot;: &quot;constant&quot;, &quot;constant&quot;: {&quot;value&quot;: &quot;3&quot;}}
  }
}, {
  &quot;id&quot;: &quot;73DB3395-5511-4735-ACFE-3B2EA6FF6D4C&quot;,
  &quot;type&quot;: &quot;modulo&quot;,
  &quot;x&quot;: -201,
  &quot;y&quot;: 106,
  &quot;node&quot;: {
    &quot;a&quot;: {
      &quot;type&quot;: &quot;connection&quot;,
      &quot;connection&quot;: {&quot;source&quot;: &quot;0CBDD21B-03EA-4488-98F7-11FD94C374A5&quot;, &quot;pin&quot;: &quot;element&quot;}
    }, &quot;b&quot;: {&quot;type&quot;: &quot;constant&quot;, &quot;constant&quot;: {&quot;value&quot;: &quot;5&quot;}}
  }
}, {
  &quot;id&quot;: &quot;E53761DA-5EF4-486B-A662-E78234C70F44&quot;,
  &quot;type&quot;: &quot;decisionTable&quot;,
  &quot;x&quot;: -95,
  &quot;y&quot;: 25,
  &quot;node&quot;: {
    &quot;$0&quot;: {&quot;type&quot;: &quot;connection&quot;, &quot;connection&quot;: {&quot;source&quot;: &quot;B0BE0799-454E-4498-97D1-BD88809BEC12&quot;, &quot;pin&quot;: &quot;out&quot;}},
    &quot;$1&quot;: {&quot;type&quot;: &quot;connection&quot;, &quot;connection&quot;: {&quot;source&quot;: &quot;73DB3395-5511-4735-ACFE-3B2EA6FF6D4C&quot;, &quot;pin&quot;: &quot;out&quot;}},
    &quot;mapping&quot;: {&quot;type&quot;: &quot;constant&quot;, &quot;constant&quot;: { &quot;value&quot;: [
      [ [ true, false ] ],
      [ [ false, true ] ],
      [ [ true, true ] ],
      [ [ false, false ] ]
    ]
    }}
  }
}, {
  &quot;id&quot;: &quot;D92A544A-D611-462E-B48B-F26784685CB7&quot;,
  &quot;type&quot;: &quot;stringInterpolation&quot;,
  &quot;x&quot;: -202,
  &quot;y&quot;: 187,
  &quot;node&quot;: {
    &quot;$0&quot;: {&quot;type&quot;: &quot;connection&quot;, &quot;connection&quot;: {&quot;source&quot;: &quot;0CBDD21B-03EA-4488-98F7-11FD94C374A5&quot;, &quot;pin&quot;: &quot;element&quot;}},
    &quot;pattern&quot;: {&quot;type&quot;: &quot;constant&quot;, &quot;constant&quot;: {&quot;value&quot;: &quot;Number is {0}&quot;}}
  }
}, {
  &quot;id&quot;: &quot;BD84E1DF-2D8E-4444-B16E-4223EDC34F4B&quot;,
  &quot;type&quot;: &quot;select&quot;,
  &quot;x&quot;: 58,
  &quot;y&quot;: 92,
  &quot;node&quot;: {
    &quot;v0&quot;: {&quot;type&quot;: &quot;constant&quot;, &quot;constant&quot;: {&quot;value&quot;: &quot;Fizz&quot;}},
    &quot;v1&quot;: {&quot;type&quot;: &quot;constant&quot;, &quot;constant&quot;: {&quot;value&quot;: &quot;Buzz&quot;}},
    &quot;v2&quot;: {&quot;type&quot;: &quot;constant&quot;, &quot;constant&quot;: {&quot;value&quot;: &quot;FizzBuzz&quot;}},
    &quot;v3&quot;: {&quot;type&quot;: &quot;connection&quot;, &quot;connection&quot;: {&quot;source&quot;: &quot;D92A544A-D611-462E-B48B-F26784685CB7&quot;, &quot;pin&quot;: &quot;ret&quot;}},
    &quot;v_&quot;: {&quot;type&quot;: &quot;connection&quot;, &quot;connection&quot;: {&quot;source&quot;: &quot;E53761DA-5EF4-486B-A662-E78234C70F44&quot;, &quot;pin&quot;: &quot;ret&quot;}}
  }
}, {
  &quot;id&quot;: &quot;6F3B7A81-387E-4E89-8BCC-47C0735F81F6&quot;,
  &quot;type&quot;: &quot;forEach&quot;,
  &quot;x&quot;: 399,
  &quot;y&quot;: -1,
  &quot;width&quot;: 187,
  &quot;height&quot;: 127,
  &quot;node&quot;: {
    &quot;array&quot;: {
      &quot;type&quot;: &quot;connection&quot;,
      &quot;connection&quot;: {&quot;source&quot;: &quot;0CBDD21B-03EA-4488-98F7-11FD94C374A5&quot;, &quot;pin&quot;: &quot;ret&quot;}
    }
  }
}, {
  &quot;id&quot;: &quot;424390E7-EE8D-495F-9116-9BA8999C910B&quot;,
  &quot;type&quot;: &quot;print&quot;,
  &quot;x&quot;: 527,
  &quot;y&quot;: 42,
  &quot;node&quot;: {
    &quot;string&quot;: {
      &quot;type&quot;: &quot;connection&quot;,
      &quot;connection&quot;: {&quot;source&quot;: &quot;6F3B7A81-387E-4E89-8BCC-47C0735F81F6&quot;, &quot;pin&quot;: &quot;element&quot;}
    }
  }
}]" /></template>
<template v-slot:body>
<p>vlow is an <strong>upcoming</strong> general purpose visual programming language embracing rx streams as first class language concept; vlow has templates/ generics, protocols/ interfaces and loads of other fancy stuff</p>
</template>
</Layout>
</template>
<script>
import Layout from "@/components/Homepage.vue";
export default {
	components:{"Layout": Layout},
	data () {
		return {
			frontmatter: {"title":"vlow - visual reactive dataflow programming language","index":"./blog/"},
			extra: [{"title":"vlow - blog","index":".","path":"/blog/index.html","excerpt":""},{"title":"introduction","shadow":"前書き","date":"2020-04-10T00:00:00.000Z","path":"/blog/introduction.html","excerpt":""}]
		}
	}
}
</script>