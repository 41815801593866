<template>
<Layout :frontmatter="frontmatter"></Layout>
</template>
<script>
import Layout from "@/components/Error404.vue";
export default {
	components:{"Layout": Layout},
	data () {
		return {
			frontmatter: {},

		}
	}
}
</script>