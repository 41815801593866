<template>
  <vlow-pre v-if="lang==='vlow'">{{content}}</vlow-pre>
  <pre v-else :class="lang"><code>{{ cnt }}</code></pre>
</template>

<script>
import { VlowPre } from "@vlow-lang/vue-vlow";

export default {
  name: "PreCustom",
  props: {
    lang: String,
    content: String
  },
  components: {
    "vlow-pre": VlowPre
  },
  computed: {
    cnt: function () {
      if (this.lang === "vlow") {
        return JSON.parse(this.content)
      }
      if (this.lang === "vlow-type") {
        return JSON.stringify(JSON.parse(this.content), null, 4)
      } else {
        return this.content
      }
    }
  }
}
</script>

<style>
@import url(~@vlow-lang/vue-vlow/dist/vue-vlow.css);
</style>
