<template>
    <router-link :to="href" :exact-path="!hasHash">
        <slot />
    </router-link>
</template>

<script>
export default {
  name: "ACustom",
  props: {
    "href": String
  },
  computed: {
    hasHash () {
      return this.href.includes("#")
    }
  }
}
</script>
