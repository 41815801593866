<template>
  <div class="site-wrapper">
    <header>
      <nav>
        <div>
          <a-custom href="/" class="logo">vlow</a-custom>/<a-custom href="/blog/">blog</a-custom>/<a-custom :href="$route.path" :data-shadow="frontmatter.shadow">{{ $route.path.slice(6, -5) }}<span class="dim">.html</span></a-custom>
        </div>
        <ul>
          <li><a style="cursor: not-allowed;">download<sup>TBA</sup></a></li>
        </ul>
      </nav>
      <slot name="demo"></slot>
    </header>

    <main>
      <h1>{{frontmatter.title}}</h1>

        <slot></slot>
    </main>

  </div>
</template>

<script>
import ACustom from "@/components/ACustom.vue";

export default {
  name: "Page",
  components: {
    ACustom
  },
  props: {
    frontmatter: Object
  }
}
</script>

<style>
</style>
