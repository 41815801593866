var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "site-wrapper" },
    [
      _c(
        "header",
        [
          _c("nav", [
            _c(
              "div",
              [
                _c("a-custom", { staticClass: "logo", attrs: { href: "/" } }, [
                  _vm._v("vlow")
                ]),
                _vm._v("/"),
                _c(
                  "a-custom",
                  { staticClass: "blog", attrs: { href: "/blog" } },
                  [_vm._v("blog")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _vm._m(0)
          ]),
          _vm._v(" "),
          _vm._t("demo")
        ],
        2
      ),
      _vm._v(" "),
      _c("aside", [_vm._t("default")], 2),
      _vm._v(" "),
      _vm._l(_vm.posts, function(item) {
        return _c("main", { key: item.path }, [
          _c(
            "h2",
            [
              _c("a-custom", { attrs: { href: item.path } }, [
                _vm._v(_vm._s(item.title))
              ]),
              _c("time", [_vm._v(_vm._s(item.date.substr(0, 10)))])
            ],
            1
          ),
          _vm._v(" "),
          _c("article", { domProps: { innerHTML: _vm._s(item.excerpt) } })
        ])
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c("a", { staticStyle: { cursor: "not-allowed" } }, [
          _vm._v("download"),
          _c("sup", [_vm._v("TBA")])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }