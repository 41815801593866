var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "site-wrapper" }, [
    _c(
      "header",
      [
        _c("nav", [
          _c(
            "div",
            [
              _c("a-custom", { staticClass: "logo", attrs: { href: "/" } }, [
                _vm._v("vlow")
              ]),
              _vm._v("/"),
              _c("a-custom", { attrs: { href: "/blog/" } }, [_vm._v("blog")]),
              _vm._v("/"),
              _c(
                "a-custom",
                {
                  attrs: {
                    href: _vm.$route.path,
                    "data-shadow": _vm.frontmatter.shadow
                  }
                },
                [
                  _vm._v(_vm._s(_vm.$route.path.slice(6, -5))),
                  _c("span", { staticClass: "dim" }, [_vm._v(".html")])
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm._m(0)
        ]),
        _vm._v(" "),
        _vm._t("demo")
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "main",
      [
        _c("h1", [_vm._v(_vm._s(_vm.frontmatter.title))]),
        _vm._v(" "),
        _vm._t("default")
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c("a", { staticStyle: { cursor: "not-allowed" } }, [
          _vm._v("download"),
          _c("sup", [_vm._v("TBA")])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }